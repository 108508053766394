import React from 'react';
import { array, bool, func, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { LISTING_TYPE_COMPANY, propTypes } from '../../../util/types';
import { ensureCompanySearchOrUserType } from '../../../util/data';
import { ListingCard } from '../../../components';
import InfiniteScroll from 'react-infinite-scroll-component';

import css from './SearchResultsPanel.module.css';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    setActiveListing,
    type,
    isMapOpen,
    onSearchListingsWithOptions,
    mapPinList,
    usersWithTransactions,
    userLoginName,
    connectedSearch,
  } = props;
  const isCompanyUser = ensureCompanySearchOrUserType(type);
  const classes = classNames(rootClassName || css.root, className);
  const listingCardsClasses = classNames(
    isCompanyUser ? css.listingCards : css.listingCardsTalent, // visible map
    { [isCompanyUser ? css.listingCardsCollapsed : css.listingCardsTalentCollapsed]: !isMapOpen } // collapsed map
  );

  // const paginationLinks =
  //   pagination && pagination.totalPages > 1 ? (
  //     <PaginationLinks
  //       className={css.pagination}
  //       pageName="SearchPage"
  //       pagePathParams={{ type }}
  //       pageSearchParams={search}
  //       pagination={pagination}
  //     />
  //   ) : null;

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const hasMoreListings = pagination ? listings.length < pagination.totalItems : !pagination;
  return (
    <div className={classes}>
      <InfiniteScroll
        className={listingCardsClasses}
        dataLength={listings.length}
        next={onSearchListingsWithOptions}
        hasMore={hasMoreListings}
      >
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
            type={type}
            mapPinList={mapPinList}
            usersWithTransactions={usersWithTransactions}
          />
        ))}
      </InfiniteScroll>
      {props.children}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  type: LISTING_TYPE_COMPANY,
  isMapOpen: true,
  mapPinList:null,
  usersWithTransactions:[],
  userLoginName:null,
  connectedSearch:null,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  type: propTypes.listingType.isRequired,
  isMapOpen: bool.isRequired,
  mapPinList:object,
  usersWithTransactions:array,
  userLoginName:string,
  connectedSearch:string,
};

export default SearchResultsPanel;
